export default {
  quizQuestions (state) {
    return state.quiz_questions
  },

  private_ (state) {
    return state.private
  },

  subject (state) {
    return state.subject
  },

  error (state) {
    return state.error
  },

  errorState (state) {
    return state.has_error
  },

  loading (state) {
    return state.loading
  },

  has_started (state) {
    return state.has_started
  },

  uuid (state) {
    return state.uuid
  },

  hasSubmitted (state) {
    return state.has_submitted
  },

  name (state) {
    return state.name
  },

  reportCard (state) {
    return state.report_card
  },

  gradedQuizzes (state) {
    return state.graded_quizzes
  }
}
